<template>
    <div class="c-app flex-row align-items-center">
      <CContainer>
        <CRow class="justify-content-center">
          <CCol md="8">
            <md-card>
              <form action="#" @submit.prevent="submit">
                <md-card-header>
                    <h1 class="md-title">Forgot password</h1>
                </md-card-header>

                <md-card-content>
                    <CRow>
                        <CCol sm="12">
                            <md-field :class="getValidationClass('email')">
                                <label>Type email acccount</label>
                                <md-input v-model="email"></md-input>
                                <span class="md-error" v-if="!$v.email.required">email is required</span>
                                <span class="md-error" v-if="!$v.email.email">Not valid email address</span>
                                <span class="md-helper-text md-subheading" v-if="success">{{ success }}</span>
                            </md-field>

                            
                            <md-field class="md-invalid" v-if="error">
                              <span class="md-error md-subheading up-text-confirmed">{{ error }}</span>
                            </md-field>
                            
                        </CCol>
                        
                    </CRow>
                    <CRow class="mt-3">
                          <CCol sm="12">
                            <md-button class="md-raised full-width-button md-primary" type="submit" :disabled="isBusy">
                               {{ isBusy ? 'Sending password reset email' :  'Send password reset email'}} 
                                <md-progress-bar md-mode="indeterminate" v-show="isBusy"></md-progress-bar>
                            </md-button>
                        </CCol>
                    </CRow>
                    <p>Do not have an account?</p>
                    <md-button class="md-raised full-width-button" @click="register">Register</md-button>
                    <p class="text-center md-subheading mt-2 mb-2">OR</p>
                    <md-button class="md-raised full-width-button" @click="login">Login</md-button>
                </md-card-content>
              </form>
          
            </md-card>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </template>
  
  <script>
  import axios from "./../../helpers/axios";
  
  import { validationMixin } from 'vuelidate'
  import {
      required,
      between,
      email,
      minLength,
      maxLength
  } from 'vuelidate/lib/validators'
  
  export default {
    name: "ForgotPassword",
    mixins: [validationMixin],
    data() {
      return {
        isBusy: false,
        email: "",
        error: null,
        success: null
      };
    },
    methods: {
      validate(){
          let email,password;
          email = this.$v.email.$invalid;
          
          return !email ? true : false ;
  
  
      },
      getValidationClass (fieldName) {
          let field = null;
  
          
          field = this.$v[fieldName];
          
          
          
          if (field) {
              return {
                  'md-invalid': field.$invalid && field.$dirty
              }
          }
      },
      register () {
      
        this.$router.push({
          path: `register`,
        })
      },
      login () {
      
        this.$router.push({
            path: `login`,
        })
        },
      submit() {
  
        if(this.validate()) {
            this.isBusy = true
            this.$firebase.auth().sendPasswordResetEmail(this.email)
            .then(() => {
                // Password reset email sent!
                // ..
                console.log('Password reset email sent!');
                this.success = 'Password reset email sent! Please check spam box just in case'
                this.isBusy = false;
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                this.error = error.message + ' Double check spelling'
                this.isBusy = false;
                // ..
            });


         
  
        } else {
          this.$v.$touch()
        }
        
      },
    },
    validations: {
                 
      email: {
          required,
          email
      },
      password: {
          required,
          minLength: minLength(6)
      }
            
        
    }
  };
  </script>
  
  <style>
  .md-field.md-invalid  {
    
    padding-top:0px;
    margin: 0px 0 50px;
    min-height: 48px;
  }
  .md-field .md-error {
    font-size: 18px;

  }

  .md-helper-text.md-subheading {
    font-size: 18px;
  }
  </style>